import {
  ConversationResponseModel,
  LongestMonologue,
} from '@capturi/api-conversations'
import { useOrganization } from '@capturi/stores'
import { Table, Tbody, Td, Tr } from '@chakra-ui/react'
import { select, t } from '@lingui/macro'
import React from 'react'
import { useEffectOnce } from 'react-use'

import { Hit } from '../Audio/types'

type StatisticsViewProps = {
  conversation: ConversationResponseModel
  agentName?: string
  setHits: (hits: Hit[]) => void
}

const StatisticsView: React.FC<StatisticsViewProps> = ({
  conversation,
  agentName,
  setHits,
}) => {
  const { organizationType } = useOrganization()
  const { insights } = conversation

  useEffectOnce(() => {
    setHits?.([])
  })

  const renderRow = (
    label: string,
    value: React.ReactNode,
  ): React.ReactElement => {
    return (
      <Tr key={label}>
        <Td>{label}</Td>
        <Td>{value}</Td>
      </Tr>
    )
  }

  const renderSpeakingRate = (
    agentName?: string,
    value?: number,
  ): React.ReactElement | null => {
    if (!agentName || value == null) {
      return null
    }
    return renderRow(
      t`Speech rate for ${agentName}`,
      t`${value.toFixed()} words/minute`,
    )
  }

  const renderLongestMonologue = (
    agentName?: string,
    value?: LongestMonologue,
  ): React.ReactElement | null => {
    if (!agentName || value == null) {
      return null
    }
    return renderRow(
      t`Longest monologue by ${agentName}`,
      t`${value.duration.toFixed()} seconds`,
    )
  }

  const output = [
    renderSpeakingRate(agentName, insights?.salesPerson?.wordsPerMinute),
    renderSpeakingRate(
      select(organizationType, {
        public: 'Citizen',
        other: 'Customer',
      }),
      insights?.otherPerson?.wordsPerMinute,
    ),
    renderLongestMonologue(agentName, insights?.salesPerson?.longestMonologue),
    renderLongestMonologue(
      select(organizationType, {
        public: 'Citizen',
        other: 'Customer',
      }),
      insights?.otherPerson?.longestMonologue,
    ),
  ]

  return (
    <Table variant="simple" w="100%">
      <Tbody>{output}</Tbody>
    </Table>
  )
}

export default StatisticsView
